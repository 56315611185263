import { ReactElement } from "react"
import { useRouteError } from "react-router-dom"
import { Typography, Link, Box, useTheme, useMediaQuery } from "@mui/material"

import Header from "../components/Header"
import Footer from "../components/Footer"
import { sections } from "./Layout"

type ThemeContextType = "light" | "dark"
type NoPagePropsType = {
  setTheme: React.Dispatch<React.SetStateAction<ThemeContextType>>
}
/**
 * A 404 error page for when react router fails.
 *
 * @returns The rendered 404 error page
 *
 */
function NoPage({ setTheme }: NoPagePropsType): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const error = useRouteError()
  console.error(error)

  return (
    <>
      <Header sections={sections} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "left",
          paddingX: 2,
          paddingY: isMobile ? 2 : 4,
        }}
      >
        <Typography variant="h1" sx={{ textAlign: "center" }}>
          404 Error: Page Not Found
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ textAlign: "left", marginX: 1 }}>
        Sorry, the page you're trying to reach doesn't exist. If you followed a broken link to get
        here, please let me know by emailing{" "}
        <Link href="mailto:tom@tgmcdermott.com">tom@tgmcdermott.com</Link>
      </Typography>
      <Footer setTheme={setTheme} />
    </>
  )
}

export default NoPage
