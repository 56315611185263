import { ReactElement } from "react"
import { Box, List, ListItem, ListItemIcon, ListItemText, useTheme } from "@mui/material"
import VerifiedIcon from "@mui/icons-material/Verified"
import SecurityIcon from "@mui/icons-material/Security"

/**
 * ScrollList contains the 10 selling points originally on the Scroll / Parchment of the old site.
 *
 * @param items - Array of strings or typography elements
 * @param icon - Optional string to set icon used for bullet points
 * @param justifyContent - Optional parameter to align the list
 *
 * @returns The rendered ScrollList
 *
 */
function ScrollList({
  items,
  icon = "verified",
  justifyContent = "center",
}: {
  items: (string | JSX.Element)[]
  icon?: "verified" | "shield"
  justifyContent?: "center" | "flex-start"
}): ReactElement {
  const theme = useTheme()

  function getIcon(icon: string) {
    if (icon === "shield") {
      return <SecurityIcon sx={{ fontSize: "1.3rem" }} />
    } else if (icon === "verified") return <VerifiedIcon sx={{ fontSize: "1.3rem" }} />
  }

  return (
    <Box sx={{ display: "flex", justifyContent: justifyContent }}>
      <List>
        {items.map((item, index: number) => (
          <ListItem key={index}>
            <ListItemIcon sx={{ color: theme.palette.primary.main }}>{getIcon(icon)}</ListItemIcon>
            <ListItemText primary={item} sx={{ margin: 0 }} />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default ScrollList
