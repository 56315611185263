import { ReactElement, useContext } from "react"
import { Link as RouterLink } from "react-router-dom"
import { Box, Link, Card, CardActions, CardContent, Button, Typography, useTheme } from "@mui/material"

import { ReactComponent as IntruderIcon } from "../assets/intruder.svg"
import { ReactComponent as FireIcon } from "../assets/fire.svg"
import { ReactComponent as CCTVIcon } from "../assets/cctv.svg"

import { ReactComponent as IntruderIconLight } from "../assets/intruder_light.svg"
import { ReactComponent as FireIconLight } from "../assets/fire_light.svg"
import { ReactComponent as CCTVIconLight } from "../assets/cctv_light.svg"
import { WebPContext } from "../App"

type FeatureCardProps = {
  title: string
  text: string
  url: string
}
/**
 * Material ui card that highlights a main feature/selling point. Consists of a title, an icon
 * and accompanying text.
 *
 * @param title - Title text
 * @param text - Main body text
 * @param url - URL that the "Learn More" button of the card takes you to
 *
 * @returns The rendered feature card
 *
 */
function FeatureCard({ title, text, url }: FeatureCardProps): ReactElement {
  const theme = useTheme()
  const linkSupport = useContext(WebPContext)

  function getIcon() {
    const iconStyle = {
      height: "65px",
      width: "auto",
      padding: "5px",
      color: theme.palette.primary.main,
    }

    if (theme.name === "dark") {
      if (title === "Intruder Alarms") {
        return <IntruderIcon style={iconStyle} />
      } else if (title === "Fire Alarms") {
        return <FireIcon style={iconStyle} />
      } else {
        return <CCTVIcon style={iconStyle} />
      }
    }

    if (theme.name === "light") {
      if (title === "Intruder Alarms") {
        return <IntruderIconLight style={iconStyle} />
      } else if (title === "Fire Alarms") {
        return <FireIconLight style={iconStyle} />
      } else {
        return <CCTVIconLight style={iconStyle} />
      }
    }
  }

  return (
    <Card raised sx={{ textAlign: "center", height: "100%" }}>
      <CardContent sx={{ paddingBottom: "0px" }}>
        <Typography variant="h2" sx={{ marginBottom: 1, color: theme.palette.text.paper }}>
          {title}
        </Typography>
        <Box sx={{ flex: 1 }}>{getIcon()}</Box>
        <Typography variant="body1" sx={{ color: theme.palette.text.paper }}>
          {text}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          size="medium"
          component={linkSupport ? RouterLink : Link}
          to={linkSupport ? url : undefined}
          href={linkSupport ? undefined : "https://cityalarms.com/" + url}
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  )
}

export default FeatureCard
