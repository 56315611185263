import { ReactElement, useState, createContext } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ThemeProvider, CssBaseline, Container } from "@mui/material"
import useWebPSupport from "./webPSupport"

// Import pages
import Layout from "./pages/Layout"
import Home from "./pages/Home"
import About from "./pages/About"
import Intruder from "./pages/services/Intruder"
import Fire from "./pages/services/Fire"
import CCTV from "./pages/services/CCTV"
import Access from "./pages/services/Access"
import Careers from "./pages/Careers"
import Reviews from "./pages/Reviews"
import Gallery from "./pages/resources/Gallery"
import Downloads from "./pages/resources/Downloads"
import Contact from "./pages/Contact"
import NoPage from "./pages/NoPage"

// Import fonts
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

// Import theme and styles
import themes from "./themes/primary"
type ThemeContextType = "light" | "dark"
const savedTheme: ThemeContextType =
  (localStorage.getItem("appTheme") as ThemeContextType) || "dark"
export const WebPContext = createContext(true)

/**
 * Main App component.
 *
 * @returns The website
 *
 */
function App(): ReactElement {
  const [theme, setTheme] = useState<ThemeContextType>(savedTheme)
  const webP = useWebPSupport()

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout setTheme={setTheme} />,
      errorElement: <NoPage setTheme={setTheme} />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "services/",
          children: [
            {
              index: true,
              element: <Intruder />,
            },
            {
              path: "intruder",
              element: <Intruder />,
            },
            {
              path: "fire",
              element: <Fire />,
            },
            {
              path: "cctv",
              element: <CCTV />,
            },
            {
              path: "access",
              element: <Access />,
            },
          ],
        },
        {
          path: "careers",
          element: <Careers />,
        },
        {
          path: "reviews",
          element: <Reviews />,
        },
        {
          path: "resources/",
          children: [
            {
              index: true,
              element: <Gallery />,
            },
            {
              path: "gallery",
              element: <Gallery />,
            },
            {
              path: "downloads",
              element: <Downloads />,
            },
          ],
        },
        {
          path: "contact",
          element: <Contact />,
        },
        // EXTRA PATHS THAT USED TO BE ON OLD WEBSITE. FOR OUTDATED LINKS.
        {
          path: "intruder-alarms-romford-essex",
          element: <Intruder />,
        },
        {
          path: "fire-alarms-romford-essex",
          element: <Fire />,
        },
        {
          path: "fire-alarms-2",
          element: <Fire />,
        },
        {
          path: "cctv-security-romford-essex",
          element: <CCTV />,
        },
        {
          path: "cctv-2",
          element: <CCTV />,
        },
        {
          path: "access-control-intercoms-romford-essex",
          element: <Access />,
        },
        {
          path: "vacancies",
          element: <Careers />,
        },
        {
          path: "gallery",
          element: <Gallery />,
        },
        {
          path: "downloads",
          element: <Downloads />,
        },
      ],
    },
  ])

  return (
    <WebPContext.Provider value={webP}>
      <ThemeProvider theme={themes[theme]}>
        <CssBaseline />
        <Container sx={{ background: themes[theme].palette.background.default }} maxWidth="lg">
          <RouterProvider router={router} />
        </Container>
      </ThemeProvider>
    </WebPContext.Provider>
  )
}

export default App
