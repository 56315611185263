import { ReactElement, useContext } from "react"
import { Typography, Box, useTheme, useMediaQuery } from "@mui/material"
import { WebPContext } from "../../App"

import Headline from "../../components/Headline"
import MediaCard from "../../components/MediaCard"
import ScrollList from "../../components/ScrollList"

import mcp from "../../assets/mcp.webp"
import smoke from "../../assets/smoke.webp"
import sounder from "../../assets/sounder.webp"

import mcp_png from "../../assets/png/mcp.png"
import smoke_png from "../../assets/png/smoke.png"
import sounder_png from "../../assets/png/sounder.png"

/**
 * Page about Fire Alarm systems.
 *
 * @returns The rendered Fire Alarms page
 *
 */
function Fire(): ReactElement {
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down("md"))
  const isTablet = useMediaQuery(theme.breakpoints.down("tb"))
  const webP = useContext(WebPContext)
  return (
    <>
      <Headline title="Fire Alarms" height="38px" width="38px" />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="body1" component="span" sx={{ paddingX: 1 }}>
          An effective fire protection system not only protects your home or business property from
          fire damage, but can also save the lives of your loved ones or employees. We can design
          and install fire alarm systems, smoke alarms, or a combination of both depending on your
          requirements. Our installations always meet the latest British and European standards. We
          also offer the following services:
          <ScrollList
            justifyContent="flex-start"
            items={[
              "Routine maintenance and emergency servicing on your fire detection system. " +
                "This is required by law.",
              "24/7 remote alarm monitoring, which allows the emergency services to be contacted " +
                "as soon as possible.",
              "Site surveys carried out by our experienced fire engineers.",
              "We can recommend experienced assessors to perform fire risk assessments. " +
                "This is required by law if 5 or more employees work in the building.",
              "We can recommend suppliers who will install fire extinguishers and perform " +
                "routine check ups to ensure that they are in working order.",
            ]}
          ></ScrollList>
        </Typography>
      </Box>
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: isTablet ? "column" : "row",
        }}
      >
        <MediaCard
          src={webP ? sounder : sounder_png}
          text="Fire alarm sounder."
          flex={true}
          width={isMedium && !isTablet ? "200px" : "250px"}
          height={isMedium && !isTablet ? "200px" : "250px"}
        />
        {isTablet ? <br /> : null}
        <MediaCard
          src={webP ? smoke : smoke_png}
          text="Smoke detector with sounder."
          flex={true}
          width={isMedium && !isTablet ? "200px" : "250px"}
          height={isMedium && !isTablet ? "200px" : "250px"}
        />
        {isTablet ? <br /> : null}
        <MediaCard
          src={webP ? mcp : mcp_png}
          text="Manual fire alarm call point."
          flex={true}
          width={isMedium && !isTablet ? "200px" : "250px"}
          height={isMedium && !isTablet ? "200px" : "250px"}
        />
      </Box>
    </>
  )
}

export default Fire
