import { ReactElement } from "react"
import { Box, Button, useTheme, useMediaQuery } from "@mui/material"

/**
 * Two contact buttons for telephone and email link which opens the relevant applications.
 * @param marginY - Override the marginY parameter of the containing Box
 *
 * @returns The rendered contact buttons
 *
 */
function ContactButtons({ marginY = 3 }: { marginY?: number }): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isKindaSmall = useMediaQuery(theme.breakpoints.down("ks"))
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isKindaSmall ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        marginX: 0,
        marginY: marginY,
      }}
    >
      <Button
        variant="contained"
        href="tel:01708437000"
        sx={{
          mx: isMobile ? 0.5 : 1,
          fontSize: "1rem",
          zIndex: 1,
          padding: isMobile ? 0.8 : null,
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        01708 437000
      </Button>
      <Button
        variant="contained"
        href="mailto:info@cityalarms.com"
        sx={{
          textTransform: "lowercase",
          mx: isMobile ? 0.5 : 1,
          mt: isKindaSmall ? 1 : 0,
          fontSize: "1rem",
          zIndex: 1,
          padding: isMobile ? 0.8 : null,
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        info@cityalarms.com
      </Button>
    </Box>
  )
}

export default ContactButtons
