import { ReactElement, useState } from "react"
import {
  Typography,
  Box,
  Button,
  Modal,
  Fade,
  Backdrop,
  useTheme,
  useMediaQuery,
} from "@mui/material"

import MediaCard from "./MediaCard"
import CloseIcon from "@mui/icons-material/Close"

type ModalCardProps = {
  cardWidth: string
  cardHeight: string
  zoomWidth: string
  zoomHeight: string
  src: string
  caption: string
}
/**
 * Media card that you can click to zoom into a full screen Image Modal.
 * @param cardWidth - Media card width
 * @param cardHeight - Media card height
 * @param zoomWidth - Fully zoomed image width
 * @param zoomHeight - Fully zoomed image height
 * @param src - image source
 * @param caption - image caption
 * @returns the rendered modal
 */
function ModalCard({
  cardWidth,
  cardHeight,
  zoomWidth,
  zoomHeight,
  src,
  caption,
}: ModalCardProps): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  type ImageModalProps = {
    width: string
    height: string
    src: string
    caption: string
  }
  /**
   * Image modal that zooms an image into full screen.
   * @param width - image width
   * @param height - image height
   * @param src - image source
   * @param caption - image caption
   * @returns the rendered modal
   */
  function ImageModal({ width, height, src, caption }: ImageModalProps): ReactElement {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        disableAutoFocus={true}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              border: "2px solid",
              borderRadius: 5,
              borderColor: "text.paper",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={src} alt={caption} width={width} height={height} />
            <Typography
              variant="body2"
              sx={{ textAlign: "center", color: "text.paper", fontSize: "1.2rem", marginBottom: 2 }}
            >
              {caption}
            </Typography>
            <Button
              onClick={handleClose}
              sx={{ marginBottom: 2 }}
              startIcon={<CloseIcon sx={{ marginBottom: 0.1 }} />}
            >
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>
    )
  }

  return (
    <>
      <MediaCard
        src={src}
        text={caption}
        flex={true}
        width={cardWidth}
        height={cardHeight}
        onClick={isMobile ? undefined : handleOpen}
      />
      {isMobile ? null : (
        <ImageModal width={zoomWidth} height={zoomHeight} src={src} caption={caption} />
      )}
    </>
  )
}

export default ModalCard
