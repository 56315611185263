import { ReactElement, useState, useEffect } from "react" // eslint-disable-line
import {
  Typography,
  Box,
  Link,
  Switch,
  Modal,
  Fade,
  Backdrop,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material"

import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import CookieIcon from "@mui/icons-material/Cookie"

type ThemeContextType = "light" | "dark"
type ThemeSwitcherPropsType = {
  setTheme: React.Dispatch<React.SetStateAction<ThemeContextType>>
}

/**
 * Theme switcher component for switching between light and dark mode
 *
 * @param setTheme - setTheme hook
 * @returns the rendered theme switcher component
 */
function ThemeSwitcher({ setTheme }: ThemeSwitcherPropsType): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [allowCookies, setAllowCookies] = useState("undecided") // yes, no, undecided

  // for cookie policy modal
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (localStorage.getItem("appTheme")) {
      setAllowCookies("yes")
    }
  }, [])

  /**
   * Changes the theme.
   * If cookies are allowed, save the theme.
   * If preference is not known, ask the user.
   */
  const handleThemeChange = () => {
    if (theme.name === "dark") {
      setTheme("light")

      if (allowCookies === "yes") {
        localStorage.setItem("appTheme", "light")
      } else if (allowCookies === "undecided") {
        handleOpen()
      }
    } else {
      setTheme("dark")

      if (allowCookies === "yes") {
        localStorage.setItem("appTheme", "dark")
      } else if (allowCookies === "undecided") {
        handleOpen()
      }
    }
  }

  function acceptCookies() {
    setAllowCookies("yes")
    localStorage.setItem("appTheme", theme.name)
    handleClose()
  }

  function declineCookies() {
    setAllowCookies("no")
    localStorage.removeItem("appTheme")
    handleClose()
  }

  // hash key changes themes - for debugging

  // useEffect(() => {
  //   function handleKeyPress(event: KeyboardEvent) {
  //     if (event.key === "#") {
  //       handleThemeChange()
  //     }
  //   }
  //   window.addEventListener("keydown", handleKeyPress)
  //   return () => window.removeEventListener("keydown", handleKeyPress)
  // }, [theme, setTheme, handleThemeChange])

  /**
   * Modal that asks the user if they want to enable cookies.
   *
   * @returns the rendered modal
   */
  function CookieModal() {
    return (
      <>
        <Typography
          variant="body2"
          align={isMobile ? "left" : "right"}
          style={{ color: theme.palette.text.footer }}
        >
          <Link onClick={handleOpen} sx={{ "&:hover": { cursor: "pointer" } }}>
            Cookie Policy
          </Link>
        </Typography>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={declineCookies}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          disableAutoFocus={true}
        >
          <Fade in={open}>
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                border: "2px solid",
                borderRadius: 5,
                borderColor: "text.paper",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "600px",
                maxWidth: "80%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  paddingTop: 3,
                  paddingBottom: 1,
                }}
              >
                <CookieIcon color="primary" />
                <Typography variant="h2" sx={{ textAlign: "center", paddingX: 1 }}>
                  Cookie Policy
                </Typography>
              </Box>

              <Typography variant="body1" sx={{ padding: 2, textAlign: "center" }}>
                A cookie is used to remember your choice of colour theme.
              </Typography>
              <Box>
                <Button
                  onClick={acceptCookies}
                  sx={{ marginBottom: 2, marginRight: 1 }}
                  startIcon={<CheckIcon sx={{ marginBottom: 0.3 }} />}
                >
                  Accept
                </Button>
                <Button
                  onClick={declineCookies}
                  sx={{ marginBottom: 2, marginLeft: 1 }}
                  startIcon={<CloseIcon sx={{ marginBottom: 0.2 }} />}
                >
                  Decline
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </>
    )
  }

  return (
    <>
      <CookieModal />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="body2" style={{ color: theme.palette.text.footer }}>
          Dark Mode
        </Typography>
        <Switch
          checked={theme.name === "dark"}
          onChange={() => handleThemeChange()}
          id="theme switcher"
        />
        {/* For debugging */}
        {/* <span>{allowCookies}</span>
        <span>{theme.name}</span>
        <span>{localStorage.getItem("appTheme")}</span> */}
      </Box>
    </>
  )
}

export default ThemeSwitcher
