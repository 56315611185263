import { ReactElement } from "react"
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  useTheme,
  useMediaQuery,
} from "@mui/material"

type MediaCardProps = {
  src: string // png image as string
  text: string
  float?: string
  onClick? : () => void
  width?: string
  height?: string
  flex?: Boolean
}
/**
 * Media card component which renders a material ui card with an image and caption.
 *
 * @param src - Source of the image
 * @param text - Caption for the image
 * @param float - Float CSS property of the card
 * @param width - Width of the card
 * @param height - Height of the image
 * @param flex - Boolean describing whether the Box containing it is a flexbox. Use true for when
 * the card should be centered, and false for when it should float to the left or right of text.
 *
 * @returns The rendered media card
 *
 */
function MediaCard({
  src,
  text,
  float,
  onClick,
  width = "300px",
  height = "210px",
  flex = false,
}: MediaCardProps): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isTablet = useMediaQuery(theme.breakpoints.down("tb"))
  return (
    <Box
      sx={{
        display: flex ? "flex" : null,
        justifyContent: "center",
        marginX: 1,
        margin: 0
      }}
    >
      <Card
        raised
        onClick = {onClick}
        sx={{
          width: isTablet ? "100%" : width,
          maxWidth: width,
          float: float ? float : null,
          marginLeft: !isMobile && float === "right" ? 2 : 0,
          marginRight: !isMobile && float === "left" ? 2 : 0,
          marginBottom: !isTablet ? 2 : 0,
          '&:hover': {cursor: onClick ? 'pointer' : null}
        }}
      >
        <CardMedia sx={{ height: height }} image={src} onClick={() => {}} />
        <CardContent>
          <Typography variant="body2" sx={{ textAlign: "center", color: theme.palette.text.paper }}>
            {text}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}

export default MediaCard
