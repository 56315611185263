import { ReactElement, useContext } from "react"
import { Box, useTheme, useMediaQuery } from "@mui/material"
import { WebPContext } from "../App"

import ContactButtons from "./ContactButtons"
import Headline from "./Headline"

import thomasKelley from "../assets/thomas_kelley.webp"
import alevTakil from "../assets/alev_takil.webp"

import thomasKelley_png from "../assets/png/thomas_kelley.png"
import alevTakil_png from "../assets/png/alev_takil.png"

// import denizFuchidzhiev from "../assets/deniz_fuchidzhiev.webp"
// import migleSiauciulyte from "../assets/migle_siauciulyte.webp"
// import aronVan from "../assets/aron_van_de_pol.webp"

// const welcomeImagesDark = [thomasKelley, denizFuchidzhiev, migleSiauciulyte]
// const welcomeImagesLight = [alevTakil, aronVan]

/**
 * Welcome image for the home page
 *
 * @returns the rendered welcome image
 */
function DesktopWelcome(): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const webP = useContext(WebPContext)
  const lightImage = webP ? alevTakil : alevTakil_png
  const darkImage = webP ? thomasKelley : thomasKelley_png
  const welcomeImage = theme.name === "light" ? lightImage : darkImage
  return (
    <Box sx={{ position: "relative", marginY: 2, marginX: 2 }}>
      <Box
        sx={{
          position: "absolute",
          zIndex: 1,
          left: "50%",
          transform: `translate(-50%, -50%)`,
          top: isMobile ? "25%" : "15%",
          width: "100%",
        }}
      >
        <Headline
          title="For All Your Security Needs"
          width="39px"
          height="39px"
          paddingBottom={2}
        />
        <ContactButtons marginY={0} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={welcomeImage}
          alt="London Skyline"
          style={{
            width: "100%",
            maxWidth: "900px",
            position: "relative",
            pointerEvents: "none",
            zIndex: 0,
            borderRadius: 30,
          }}
        />
      </Box>
    </Box>
  )
}

export default DesktopWelcome
