import { ReactElement, useContext } from "react"
import { Typography, Box, useTheme, useMediaQuery } from "@mui/material"
import { WebPContext } from "../../App"

import Headline from "../../components/Headline"
import MediaCard from "../../components/MediaCard"
import ScrollList from "../../components/ScrollList"

import dome from "../../assets/dome_viper.webp"
import bullet from "../../assets/bullet_viper.webp"

import dome_png from "../../assets/png/dome_viper.png"
import bullet_png from "../../assets/png/bullet_viper.png"

/**
 * Page about CCTV systems.
 *
 * @returns The rendered CCTV page
 *
 */
function CCTV(): ReactElement {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("tb"))
  const webP = useContext(WebPContext)
  return (
    <>
      <Headline title="CCTV" height="32px" width="43px" />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="body1" component="span" sx={{ paddingX: 1 }}>
          Restricting access to your property is vital - but monitoring and surveillance are equally
          as essential in keeping potential intruders, burglars or vandals at bay. Simultaneously
          acting as a deterrent and a method of recording any criminal activity for evidence, our
          CCTV systems utilise state of the art technology installed by our security experts and
          provide an effective way to boost your on-site security.
          <br />
          <br />
          We install modern high-definition IP (internet protocol) CCTV systems, so that whether you
          need one or two cameras to monitor an entrance into your home, or you need a comprehensive
          surveillance system for your business - we can provide the correct CCTV solution for you.
          <ScrollList
            justifyContent="flex-start"
            items={[
              <Typography>
                Our CCTV cameras capture high-quality video footage which is saved to a hard drive.
              </Typography>,
              <Typography>
                If required, we can arrange for you to view your CCTV via an app on your computer,
                tablet or smartphone.
              </Typography>,
              <Typography>
                We typically recommend dome cameras as they are more covert, but can also provide
                more traditional looking bullet cameras.
              </Typography>,
              <Typography>
                An experienced consultant can carry out a thorough on-site security survey, allowing
                us to design the perfect CCTV system for your property.
              </Typography>,
            ]}
          ></ScrollList>
        </Typography>
      </Box>
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: isTablet ? "column" : "row",
        }}
      >
        <MediaCard
          src={webP ? dome : dome_png}
          text="Dome camera."
          flex={true}
          width={"250px"}
          height={"250px"}
        />
        {isTablet ? <br /> : null}
        <MediaCard
          src={webP ? bullet : bullet_png}
          text="Bullet camera."
          flex={true}
          width={"250px"}
          height={"250px"}
        />
      </Box>
    </>
  )
}

export default CCTV
