import { ReactElement } from "react"
import { Typography, Box, Link } from "@mui/material"

import Headline from "../../components/Headline"
import FeatureGrid from "../../components/FeatureGrid"

const forms = [
  {
    title: "City Alarms Terms & Conditions",
    url: "forms/terms-and-conditions.pdf",
  },
  {
    title: "Simple User Instructions",
    url: "forms/simple_user.pdf",
  },
  {
    title: "Hazards and Site Risk Statement",
    url: "forms/hazards-and-site-risk.pdf",
  },
  {
    title: "Keyholders Form",
    url: "forms/keyholders.jpg",
  },
]
const wiredAlarms = [
  {
    title: "Menvier TS790 / TS900 User Manual",
    url: "wiredAlarms/ts790-ts900-user-manual.pdf",
  },
  {
    title: "Galaxy 8/18/60/128/500/504/512 User Guide",
    url: "wiredAlarms/galaxy-user-guide.pdf",
  },
  {
    title: "Galaxy Dimension User Guide",
    url: "wiredAlarms/galaxy-dimension-user-guide.pdf",
  },
  {
    title: "Texecom LCDP Keypad User Guide",
    url: "wiredAlarms/lcdp-user-guide.pdf",
  },
  {
    title: "Texecom Premier 24/48/88/168 User Guide",
    url: "wiredAlarms/texecom-premier-user-guide.pdf",
  },
  {
    title: "Scantronic 9751 Control Panel User Guide",
    url: "wiredAlarms/scantronic-9751-user-guide.pdf",
  },
  {
    title: "Scantronic 9651 Technical Specification",
    url: "wiredAlarms/scantronic-9651-technical-specificaion.pdf",
  },
  {
    title: "ADE Accenta 8 Operating Instructions",
    url: "wiredAlarms/accenta-8-operating-instructions.pdf",
  },
  {
    title: "Gardtec 800-816-840 User Instructions",
    url: "wiredAlarms/gardtec-800-user-instructions.pdf",
  },
  {
    title: "Gardtec 595 Control Panel User Instructions",
    url: "wiredAlarms/gardtec-595-user-instructions.pdf",
  },
  {
    title: "GT 600/601 Control Panel User Instructions",
    url: "wiredAlarms/gt600-gt601-user-instructions.pdf",
  },
]
const wirelessAlarms = [
  {
    title: "Infinite Prime Control Panel User Guide",
    url: "wirelessAlarms/infinite-prime-user-manual.pdf",
  },
  {
    title: "Visonic PowerMaxPro User Guide",
    url: "wirelessAlarms/powermaxpro-user-guide.pdf",
  },
  {
    title: "Scantronic Homelink 75 Administrator's Guide",
    url: "wirelessAlarms/homelink75-administrators-guide.pdf",
  },
  {
    title: "Scantronic i-on 16 User Guide",
    url: "wirelessAlarms/ion-16-user-guide.pdf",
  },
  {
    title: "Agility 3 User Manual",
    url: "wirelessAlarms/agility-3-user-manual.pdf",
  },
]
const cctv = [
  {
    title: "Dedicated Micros Digital Sprite 2 (DS2) Operating Guide",
    url: "cctv/operating-ds2.pdf",
  },
  {
    title: "Dedicated Micros EcoSense Quick Start Guide",
    url: "cctv/ecosense-quick-start.pdf",
  },
  {
    title: "Dedicated Micros EcoSense Installation and Operation Guide",
    url: "cctv/ecosense-guide.pdf",
  },
  {
    title: "Evidence Download Pack",
    url: "cctv/evidence-download-pack.pdf",
  },
]
const audioIntercoms = [
  {
    title: "Videx Intercom with Access Code Lock User Guide",
    url: "audioIntercoms/videx-intercom-user-guide.pdf",
  },
  {
    title: "Comelit PowerCode Keypad Instructions",
    url: "audioIntercoms/comelit-powercode-instructions.pdf",
  },
  {
    title: "Fermax 4860 Technical-Specifications",
    url: "audioIntercoms/fermax-4860-technical-specifications.pdf",
  },
  {
    title: "Fermax Intercom Keypad Instructions",
    url: "audioIntercoms/fermax-keypad-manual.pdf",
  },
  {
    title: "Comelit SimpleKey Technical Sheet",
    url: "audioIntercoms/simplekey-technical-sheet.pdf",
  },
]
const videoIntercoms = [
  {
    title: "Bpt Nova Video Intercom Station User Guide",
    url: "videoIntercoms/bpt-nova-operating-instructions.pdf",
  },
  {
    title: "Comelit DIVA Kit Technical Manual",
    url: "videoIntercoms/comelit-diva-technical-manual.pdf",
  },
  {
    title: "Comelit Smart Monitor 6302S User Manual",
    url: "videoIntercoms/comelit-smart-monitor-user-manual.pdf",
  },
  {
    title: "Fermax iLoft City Video Kit Instruction Manual",
    url: "videoIntercoms/fermax-iloft-instruction-manual.pdf",
  },
]
const wirelessIntercoms = [
  {
    title: "Daitem Wireless Intercom with Keypad User Guide",
    url: "wirelessIntercoms/daitem-intercom-user-guide.pdf",
  },
  {
    title: "Daitem Wireless Doorphone Presentation",
    url: "wirelessIntercoms/daitem-doorphone-presentation.pdf",
  },
  {
    title: "Vu-Com Wireless Video Door Entry System",
    url: "wirelessIntercoms/vu-com.pdf",
  },
]
const videoDoorEntry = [
  {
    title: "Urmet IPerVoice Switchboard Installation and User Manual",
    url: "videoDoorEntry/ipervoice-user-manual.pdf",
  },
]

type DownloadListProps = {
  title: string
  downloads: {
    title: string
    url: string
  }[]
}
/**
 * List of downloads of a particular category e.g. Intruder Alarms, Fire Alarms, CCTV etc.
 *
 * @param title - Title of the category
 * @param downloads - List of download objects
 * @param download.title - Title of the linked download media
 * @param download.url - URL of the linked download media
 * @returns The rendered list of downloads
 */
function DownloadList({ title, downloads }: DownloadListProps): ReactElement {
  return (
    <>
      <Typography variant="h2" sx={{ paddingX: 2, marginBottom: 1 }}>
        {title}
      </Typography>
      {downloads.map((entry, i) => (
        <Typography variant="body1" key={i} sx={{ paddingX: 2, paddingY: 0.75 }}>
          <Link href={"../../files/" + entry.url} target="_blank" rel="noopener noreferrer">
            {i + 1}. {entry.title}
          </Link>
        </Typography>
      ))}
    </>
  )
}

/**
 * Downloads page.
 *
 * @returns The downloads page
 */
function Downloads(): ReactElement {
  return (
    <>
      <Headline title="Downloads" width="37px" height="37px" />
      <FeatureGrid sm={6} xs={12}>
        <Box>
          <DownloadList title="Information and Forms" downloads={forms} />
          <br />
          <DownloadList title="Wired Intruder Alarms" downloads={wiredAlarms} />
          <br />
          <DownloadList title="Wireless Intruder Alarms" downloads={wirelessAlarms} />
          <br />
        </Box>
        <Box>
          <DownloadList title="CCTV" downloads={cctv} />
          <br />
          <DownloadList title="Audio Intercoms" downloads={audioIntercoms} />
          <br />
          <DownloadList title="Video Intercoms" downloads={videoIntercoms} />
          <br />
          <DownloadList title="Wireless Intercoms" downloads={wirelessIntercoms} />
          <br />
          <DownloadList title="Video Door Entry Systems" downloads={videoDoorEntry} />
          <br />
        </Box>
      </FeatureGrid>
    </>
  )
}

export default Downloads
