import { ReactElement, useContext } from "react"
import { Link as RouterLink } from "react-router-dom"
import { Link, Box, Toolbar, useMediaQuery, useTheme } from "@mui/material"
import { WebPContext } from "../App"

import DesktopNav from "./DesktopNav"
import MobileNav from "./MobileNav"
import { sectionsType } from "../pages/Layout"

import logoBlack from "../assets/logo.webp"
import logoWhite from "../assets/logo_white.webp"
import logoBlack_png from "../assets/png/logo.png"
import logoWhite_png from "../assets/png/logo_white.png"

// svg versions where you can customize text color. not to be included in build version
// because they contain inline png images which don't get compressed, increasing bundle size
// import { ReactComponent as LogoBlack } from "../assets/logo_customizable.svg"
// import { ReactComponent as LogoWhite } from "../assets/logo_white_customizable.svg"

import nsi from "../assets/nsi.webp"
import which from "../assets/which.webp"
import nsi_png from "../assets/png/nsi.png"
import which_png from "../assets/png/which.png"

/**
 * Header component which renders site logos and either the mobile or desktop navigation menu.
 *
 * @param props.sections - An array of section objects
 * @param props.section.title - The title of the section
 * @param props.section.url - The URL of the section
 * @param props.section.icon - The icon component for the section
 * @param props.section.subsections - An array of subsection components. Each subsection
 * has the same properties as a main section object.
 * @returns The rendered header component
 *
 */
function Header({ sections }: { sections: sectionsType }): ReactElement {
  const theme = useTheme()
  const webP = useContext(WebPContext)
  const linkSupport = webP
  const isTablet = useMediaQuery(theme.breakpoints.down("tb"))
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isSlightlySmall = useMediaQuery(theme.breakpoints.down("ss"))
  const isKindaSmall = useMediaQuery(theme.breakpoints.down("ks"))
  const isVerySmall = useMediaQuery(theme.breakpoints.down("vs"))

  /**
   * City Alarms logo component.
   *
   * @returns The rendered component
   *
   */
  function MainLogo(): ReactElement {
    const lightLogo = webP ? logoWhite : logoWhite_png
    const darkLogo = webP ? logoBlack : logoBlack_png
    const logo = theme.name === "light" ? lightLogo : darkLogo

    var logoHeight = "0px"
    if (isVerySmall) {
      logoHeight = "85px"
    } else if (isKindaSmall) {
      logoHeight = "100px"
    } else if (isSlightlySmall) {
      logoHeight = "120px"
    } else if (isMobile) {
      logoHeight = "130px"
    } else if (isTablet) {
      logoHeight = "140px"
    } else {
      logoHeight = "160px"
    }

    return (
      <Box
        sx={{
          display: "flex",
          flex: 2.3,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          component={linkSupport ? RouterLink : Link}
          to={linkSupport ? "/" : undefined}
          href={linkSupport ? undefined : "https://cityalarms.com"}
          style={{ display: "flex" }}
        >
          {/* svg images, not to be included in build version */}
          {/* {theme.name === "light" ? (
              <LogoWhite
                style={{ height: logoHeight, width: "auto", color: theme.palette.secondary.main }}
              />
            ) : (
              <LogoBlack
                style={{ height: logoHeight, width: "auto", color: theme.palette.primary.main }}
              />
            )} */}
          <img src={logo} alt="City Alarms logo" style={{ height: logoHeight, width: "auto" }} />
        </Link>
      </Box>
    )
  }

  /**
   * NSI logo component.
   *
   * @returns The rendered component
   *
   */
  function NsiLogo(): ReactElement {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Link
          href={
            "https://www.nsi.org.uk/company-finder/?scid=9366301d-4d05-ea11-80fd-00155d993d0a&type=quick&familyType=Security+Systems&town-postcode=&Lo=&La=&range=3&search=company&company-name=City+Alarms&regionId="
          }
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "flex" }}
        >
          <img
            src={webP ? nsi : nsi_png}
            alt="NSI gold approval"
            style={{ height: "100px", width: "auto" }}
          />
        </Link>
      </Box>
    )
  }

  /**
   * Which? trusted trader logo component.
   *
   * @returns The rendered component
   *
   */
  function WhichLogo(): ReactElement {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Link
          href={"https://trustedtraders.which.co.uk/businesses/city-alarms-ltd/"}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "flex" }}
        >
          <img
            src={webP ? which : which_png}
            alt="Which? Recommended trader"
            style={{ height: "100px", width: "auto" }}
          />
        </Link>
      </Box>
    )
  }

  return (
    <>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          marginY: 1,
        }}
      >
        {isMobile ? <MobileNav sections={sections} /> : <NsiLogo />}
        <MainLogo />
        {isMobile ? null : <WhichLogo />}
      </Toolbar>
      {isMobile ? null : <DesktopNav sections={sections} />}
    </>
  )
}

export default Header
