import React, { useEffect, ReactElement } from "react"
import {
  Box,
  Card,
  Typography,
  Button,
  MobileStepper,
  useTheme,
  useMediaQuery,
} from "@mui/material"

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import FormatQuoteIcon from "@mui/icons-material/FormatQuote"

import { quotesType } from "../pages/Reviews"

/**
 * Carousel of testimonial customer quotes that can be scrolled between.
 *
 * @param quotes - An array of quote objects
 * @param quote.text - Text of the quote
 * @param quote.author - Real author of the quote
 * @param quote.displayedName - Displayed name of the quote which could be anonymized
 *
 * @returns The rendered Quote Carousel
 *
 */
function QuoteCarousel({ quotes }: { quotes: quotesType }): ReactElement {
  const theme = useTheme()
  const isVerySmall = useMediaQuery(theme.breakpoints.down("vs"))
  const isKindaSmall = useMediaQuery(theme.breakpoints.down("ks"))
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.down("md"))

  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = quotes.length

  const millisecondsPerQuote = 8000

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }, millisecondsPerQuote)
    return () => {
      clearInterval(intervalId)
    }
  }, [activeStep])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(maxSteps - 1)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  var cardHeight = 0
  var cardWidth = 800

  if (isVerySmall) {
    cardHeight = 270
  } else if (isKindaSmall) {
    cardHeight = 220
  } else if (isMobile) {
    cardHeight = 175
  } else if (isMedium) {
    cardHeight = 130
  } else {
    cardHeight = 130
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 3 }}>
      <Box sx={{ width: cardWidth, marginX: 1 }}>
        <Card
          raised
          sx={{
            height: cardHeight,
            width: "100%",
            p: 3,
            color: theme.palette.text.paper,
            backgroundColor: theme.palette.background.paper,
            borderBottomLeftRadius: "0",
            borderBottomRightRadius: "0",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1" sx={{ mb: 1.25 }}>
            <FormatQuoteIcon
              sx={{
                fontSize: 18,
                color: theme.palette.primary.main,
                transform: "rotate(180deg)",
              }}
            />
            {quotes[activeStep % maxSteps].text}
            <FormatQuoteIcon sx={{ fontSize: 18, color: theme.palette.primary.main }} />
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.text.footer }}>
            - {quotes[activeStep % maxSteps].displayedName}
          </Typography>
        </Card>

        <MobileStepper
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            borderTop: 2,
            borderColor: "divider",
          }}
          variant="progress"
          elevation={2}
          steps={maxSteps}
          position="static"
          activeStep={activeStep % maxSteps}
          nextButton={
            <Button size="small" onClick={handleNext}>
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Box>
    </Box>
  )
}

export default QuoteCarousel
