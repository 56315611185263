import { ReactElement } from "react"
import { Typography, Link, Box } from "@mui/material"

import Headline from "../components/Headline"
import FeatureGrid from "../components/FeatureGrid"
import ContactCard from "../components/ContactCard"
import Map from "../components/Map"

function Contact(): ReactElement {
  return (
    <>
      <Headline title="Contact" height="37px" width="37px" />
      <Typography variant="body1" sx={{ marginX: 1 }}>
        Call or email us for a free quote or more information about any of our services. We do not
        use any automated systems, so you are guaranteed to speak to one of our helpful office
        staff! You can also get in touch with us via our office address:{" "}
        <Link
          href="https://goo.gl/maps/mD5Dkw9RA5VNgpKw8"
          target="_blank"
          rel="noopener noreferrer"
        >
          142-144 Hillview Avenue, Hornchurch, Essex, RM11&nbsp;2DL
        </Link>
        .
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <FeatureGrid sm={6} xs={12} spacing={1} width="700px">
          <ContactCard
            title="phone"
            text="01708 437000"
            url="tel:01708437000"
            buttonText="Call Us"
          />
          <ContactCard
            title="email"
            text="info@cityalarms.com"
            url="mailto:info@cityalarms.com"
            buttonText="Email Us"
          />
        </FeatureGrid>
      </Box>
      <Map />
    </>
  )
}

export default Contact
