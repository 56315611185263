import { ReactElement } from "react"
import { Outlet, ScrollRestoration } from "react-router-dom"

import Header from "../components/Header"
import Footer from "../components/Footer"

import HomeIcon from "@mui/icons-material/Home"
import InfoIcon from "@mui/icons-material/Info"
import SecurityIcon from "@mui/icons-material/Security"
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment"
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront"
import KeyIcon from "@mui/icons-material/Key"
import EngineeringIcon from "@mui/icons-material/Engineering"
import StarIcon from "@mui/icons-material/Star"
import DownloadIcon from "@mui/icons-material/Download"
import CollectionsIcon from "@mui/icons-material/Collections"
import PhoneIcon from "@mui/icons-material/Phone"

export const sections = [
  { title: "Home", url: "/", icon: HomeIcon, subsections: [] },
  { title: "About Us", url: "about", icon: InfoIcon, subsections: [] },
  {
    title: "Services",
    url: "services",
    icon: SecurityIcon,
    subsections: [
      {
        title: "Intruder Alarms",
        url: "services/intruder",
        icon: SecurityIcon,
      },
      {
        title: "Fire Alarms",
        url: "services/fire",
        icon: LocalFireDepartmentIcon,
      },
      { title: "CCTV", url: "services/cctv", icon: VideoCameraFrontIcon },
      {
        title: "Access Control",
        url: "services/access",
        icon: KeyIcon,
      },
    ],
  },
  { title: "Careers", url: "careers", icon: EngineeringIcon, subsections: [] },
  { title: "Reviews", url: "reviews", icon: StarIcon, subsections: [] },
  {
    title: "Resources",
    url: "resources",
    icon: CollectionsIcon,
    subsections: [
      { title: "Gallery", url: "resources/gallery", icon: CollectionsIcon },
      { title: "Downloads", url: "resources/downloads", icon: DownloadIcon },
    ],
  },
  { title: "Contact", url: "contact", icon: PhoneIcon, subsections: [] },
]

type ThemeContextType = "light" | "dark"
type LayoutPropsType = {
  setTheme: React.Dispatch<React.SetStateAction<ThemeContextType>>
}
/**
 * Layout component which defines the basic layout of each page, consisting of a header and footer.
 *
 * @param setTheme - setTheme hook to switch between light and dark modes
 * @returns The rendered layout component
 *
 */
function Layout({ setTheme }: LayoutPropsType): ReactElement {
  return (
    <>
      <ScrollRestoration />
      <Header sections={sections} />
      <Outlet />
      <Footer setTheme={setTheme} />
    </>
  )
}

export type sectionsType = typeof sections
export default Layout
