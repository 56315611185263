import { ReactElement } from "react"
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material"

type JobCardProps = {
  title: string
  responsibilities: string
  experience: string
}
/**
 * Job card component detailing an open position at the company.
 *
 * @param title - Job title
 * @param responsibilities - Brief description of job responsibilities
 * @param experience - Required experience for the job
 *
 * @returns The rendered Careers page
 *
 */
function JobCard({ title, responsibilities, experience }: JobCardProps): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const email = "info@cityalarms.com"
  const subject = `Application for ${title} position`

  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
      <Card raised sx={{ width: isMobile ? "100%" : 800, marginX: 1 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14, color: theme.palette.primary.main }} gutterBottom>
            Open Position
          </Typography>
          <Typography variant="h2" sx={{ mb: 1.5, color: theme.palette.text.paper }}>
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              mb: 0.5,
              textAlign: "left",
              color: theme.palette.text.paper,
            }}
          >
            <span style={{ color: theme.palette.primary.main }}>Responsibilities:</span>{" "}
            {responsibilities}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              textAlign: "left",
              color: theme.palette.text.paper,
            }}
          >
            <span style={{ color: theme.palette.primary.main }}>Required Experience:</span>{" "}
            {experience}
          </Typography>
        </CardContent>
        <CardActions>
          <Button component="a" size="medium" href={mailtoLink} sx={{ margin: 0.5 }}>
            Apply Now
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}

export default JobCard
