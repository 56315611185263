import { useState, MouseEvent, ReactElement, useContext } from "react"

import { Link as RouterLink, useLocation } from "react-router-dom"
import { Link, Button, Menu, MenuItem, useTheme } from "@mui/material"
import { SvgIconComponent } from "@mui/icons-material/"
import { WebPContext } from "../App"

type SubMenuProps = {
  section: {
    title: string
    url: string
    subsections: { title: string; url: string; icon: SvgIconComponent }[]
  }
}
/**
 * Sub menu component which opens when clicking its parent menu button e.g. clicking 'resources'
 * opens a sub menu containing links to the 'gallery' and 'downloads' pages.
 *
 * @param props.section - A section object
 * @param props.section.title - The title of the section
 * @param props.section.url - The URL of the section
 * @param props.section.subsections - An array of subsection components. Each subsection
 * has the same properties as a main section object.
 * @returns The rendered submenu
 *
 */
function SubMenu({ section }: SubMenuProps): ReactElement {
  const theme = useTheme()
  const path = useLocation().pathname.slice(1)
  const linkSupport = useContext(WebPContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        key={section.url}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color={path.startsWith(section.url) ? "primary" : "inherit"}
        variant={path.startsWith(section.url) ? "outlined" : "text"}
        sx={{ padding: 1, flexShrink: 0, textTransform: "capitalize" }}
      >
        {section.title}
      </Button>
      <Menu
        key={section.url + " menu"}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {section.subsections.map((subsection) => (
          <MenuItem
            onClick={handleClose}
            component={linkSupport ? RouterLink : Link}
            to={linkSupport ? subsection.url : undefined}
            href={linkSupport ? undefined : "https://cityalarms.com/" + subsection.url}
            key={subsection.url}
            sx={{
              color:
                path === subsection.url ? theme.palette.background.paper : theme.palette.text.paper,
              backgroundColor: path === subsection.url ? theme.palette.primary.main : null,
              fontWeight: path === subsection.url ? "bold" : null,
              "&:hover": {
                backgroundColor:
                  path === subsection.url
                    ? theme.palette.primary.main
                    : theme.palette.background.default,
              },
            }}
          >
            {subsection.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SubMenu
