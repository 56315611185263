import { ReactElement, useContext } from "react"
import { Typography, Box, useTheme, useMediaQuery } from "@mui/material"
import { WebPContext } from "../../App"

import Headline from "../../components/Headline"
import MediaCard from "../../components/MediaCard"
import ScrollList from "../../components/ScrollList"

import audio from "../../assets/audio.webp"
import video from "../../assets/video.webp"

import audio_png from "../../assets/png/audio.png"
import video_png from "../../assets/png/video.png"

/**
 * Page about Access Control systems.
 *
 * @returns The rendered Access Control page
 *
 */
function Access(): ReactElement {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("tb"))
  const webP = useContext(WebPContext)
  return (
    <>
      <Headline title="Access Control and Intercoms" height="35px" width="47px" />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="body1" component="span" sx={{ paddingX: 1 }}>
          <ScrollList
            justifyContent="flex-start"
            items={[
              <Typography>
                <span style={{ color: theme.palette.primary.main }}>Audio entry phones</span> -
                These enable you to speak to callers before making the decision to allow entry into
                your building. The door can then be opened manually or from a remote location via an
                electronic release mechanism.
              </Typography>,
              <Typography>
                <span style={{ color: theme.palette.primary.main }}>Video entry phones</span> -
                Video entry phones offer all of the features of the audio entry phone with the added
                advantage of being able to see your visitor. Internal monitors are available in a
                variety of finishes and can be surface or flush mounted.
              </Typography>,
              <Typography>
                <span style={{ color: theme.palette.primary.main }}>Access control systems</span> -
                The main function of access control systems are to monitor the movements of
                pedestrians and vehicles within a building. The system will control where
                individuals are able to gain access and manage user permissions to ensure that
                access is only granted to the personnel authorised to be in designated areas at
                specific times.
              </Typography>,
            ]}
          ></ScrollList>
        </Typography>
      </Box>
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: isTablet ? "column" : "row",
        }}
      >
        <MediaCard
          src={webP ? audio : audio_png}
          text="Audio intercom and handset (example)."
          flex={true}
          width={isTablet ? "200px" : "250px"}
          height={isTablet ? "200px" : "250px"}
        />
        {isTablet ? <br /> : null}
        <MediaCard
          src={webP ? video : video_png}
          text="Video intercom and handset (example)."
          flex={true}
          width={isTablet ? "280px" : "400px"}
          height={isTablet ? "180px" : "250px"}
        />
      </Box>
    </>
  )
}

export default Access
