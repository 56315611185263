import { ReactElement } from "react"
import { Grid, Box } from "@mui/material"

type FeatureGridProps = {
  children: ReactElement[]
  sm: number
  xs: number
  spacing?: number
  width?: string
}
/**
 * Material ui grid that contains multiple FeatureCard components.
 *
 * @param props.children - Array of FeatureCards
 * @param props.spacing - Spacing between cards
 * @param props.sm - Number of grid columns each card occupies when screen is small
 * @param props.xs - Number of grid columns each card occupies when screen is extra small
 * @param props.width - Width of the Box containing the FeatureGrid
 *
 * @returns The rendered feature grid
 *
 */
function FeatureGrid(props: FeatureGridProps): ReactElement {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: props.width,
        marginY: 2,
        marginX: 2,
      }}
    >
      <Grid container spacing={props.spacing}>
        {[...props.children].map((child, i) => (
          <Grid
            item
            sm={child.props.sm || props.sm}
            xs={child.props.xs || props.xs}
            key={i}
          >
            {child}
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default FeatureGrid
