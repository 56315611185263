import { ReactElement, useContext } from "react"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { Toolbar, Button, Link } from "@mui/material"

import SubMenu from "./SubMenu"
import { sectionsType } from "../pages/Layout"
import { WebPContext } from "../App"

/**
 * Desktop navigation bar containing links to other pages on the site.
 *
 * @param props.sections - An array of section objects
 * @param props.section.title - The title of the section
 * @param props.section.url - The URL of the section
 * @param props.section.icon - An icon component for the section
 * @param props.section.subsections - An array of subsection components. Each subsection
 * has the same properties as a main section object.
 * @returns The rendered desktop navigation bar
 *
 */
function DesktopNav({ sections }: { sections: sectionsType }): ReactElement {
  const path = useLocation().pathname.slice(1)
  const linkSupport = useContext(WebPContext)

  /**
   * Checks if a section is the current url.
   * @param url - The url of the section to check
   *
   * @returns True or False
   */
  function sectionIsCurrent(url: string) {
    return path.startsWith(url) || (path === "" && url === "/")
  }

  return (
    <Toolbar
      component="nav"
      variant="dense"
      sx={{
        justifyContent: "space-between",
        overflowX: "auto",
        borderBottom: 1,
        borderTop: 1,
        borderColor: "divider",
      }}
    >
      {sections.map((section) =>
        section.subsections.length > 0 ? (
          <SubMenu key={section.url} section={section} />
        ) : (
          <Button
            key={section.url}
            component={linkSupport ? RouterLink : Link}
            to={linkSupport ? section.url : undefined}
            href={linkSupport ? undefined : "https://cityalarms.com/" + section.url}
            color={sectionIsCurrent(section.url) ? "primary" : "inherit"}
            variant={sectionIsCurrent(section.url) ? "outlined" : "text"}
            sx={{ padding: 1, flexShrink: 0, textTransform: "capitalize" }}
          >
            {section.title}
          </Button>
        )
      )}
    </Toolbar>
  )
}

export default DesktopNav
