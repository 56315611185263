import { ReactElement, useContext } from "react"
import { Box, useTheme, useMediaQuery } from "@mui/material"
import { WebPContext } from "../App"

import ContactButtons from "./ContactButtons"
import Headline from "./Headline"

import thomasKelleyMobile from "../assets/thomas_kelley_mobile.webp"
import alevTakilMobile from "../assets/alev_takil_mobile.webp"
import thomasKelleyMobile_png from "../assets/png/thomas_kelley_mobile.png"
import alevTakilMobile_png from "../assets/png/alev_takil_mobile.png"

// import denizFuchidzhiev from "../assets/deniz_fuchidzhiev.webp"
// import migleSiauciulyte from "../assets/migle_siauciulyte.webp"
// import aronVan from "../assets/aron_van_de_pol.webp"

// const welcomeImagesDark = [thomasKelley, denizFuchidzhiev, migleSiauciulyte]
// const welcomeImagesLight = [alevTakil, aronVan]

/**
 * Welcome image for the home page
 *
 * @returns the rendered welcome image
 */
function MobileWelcome(): ReactElement {
  const theme = useTheme()
  const isKindaSmall = useMediaQuery(theme.breakpoints.down("ks"))
  const webP = useContext(WebPContext)
  const lightImage = webP ? alevTakilMobile : alevTakilMobile_png
  const darkImage = webP ? thomasKelleyMobile : thomasKelleyMobile_png
  const welcomeImage = theme.name === "light" ? lightImage : darkImage
  return (
    <Box sx={{ position: "relative", marginY: 2, marginX: 2 }}>
      <Box
        sx={{
          position: "absolute",
          zIndex: 1,
          left: "50%",
          transform: `translate(-50%, -50%)`,
          top: isKindaSmall ? "35%" : "20%",
          width: "100%",
        }}
      >
        <Headline
          title="For All Your Security&nbsp;Needs"
          paddingBottom={2}
          width="39px"
          height="39px"
        />
        <ContactButtons marginY={0} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={welcomeImage}
          alt="London Skyline"
          style={{
            width: "100%",
            maxWidth: "900px",
            position: "relative",
            pointerEvents: "none",
            zIndex: 0,
            borderRadius: 30,
          }}
        />
      </Box>
    </Box>
  )
}

export default MobileWelcome
