import { ReactElement } from "react"
import { Box, useTheme, useMediaQuery } from "@mui/material"

function Map(): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const mapSrc =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.80452326" +
    "0128!2d0.20786262222991966!3d51.57181694785629!2m3!1f0!2f0!3f0!3m2!" +
    "1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a4da92496e8f%3A0xc4765b57857e4e" +
    "08!2sCity%20Alarms%20Ltd!5e0!3m2!1sen!2suk!4v1685218050610!5m2!1sen!2suk"
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginX: 1.5,
        marginY: 2,
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: isMobile ? "100%" : 700,
          height: isMobile ? null : 500,
          paddingTop: isMobile ? "100%" : null,
        }}
      >
        <iframe
          src={mapSrc}
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            border: "none",
          }}
          title="Map"
        ></iframe>
      </Box>
    </Box>
  )
}

export default Map
