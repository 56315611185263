// Test for webp support. If a browser doesn't support webp, png or jpg images are loaded instead.
// Currently being used also to check for react-router link support. Under the assumption that if
// a browser is too old for react-router support, it is also too old for webp. Might not be a good
// assumption. Have to look into it more in the future.

import { useState, useEffect } from "react";

function useWebPSupport() {
  const [webPSupported, setWebPSupported] = useState(false);

  useEffect(() => {
    const testWebP = () => {
      return new Promise<boolean>((res) => {
        const webP = new Image();
        webP.src =
          "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
        webP.onload = webP.onerror = () => {
          res(webP.height === 2);
        };
      });
    };

    testWebP().then((hasWebP: boolean) => {
      setWebPSupported(hasWebP);
    });
  }, []);

  return webPSupported;
}

export default useWebPSupport;