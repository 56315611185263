import { ReactElement, useContext } from "react"
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material"
import { WebPContext } from "../App"

import Headline from "../components/Headline"
import MediaCard from "../components/MediaCard"
import QuoteCarousel from "../components/QuoteCarousel"

import thankyou from "../assets/thankyou_resized.webp"
import thankyou2 from "../assets/thankyou2_resized.webp"
import thankyou_png from "../assets/png/thankyou_resized.jpg"
import thankyou2_png from "../assets/png/thankyou2_resized.jpg"

const quotes = [
  {
    text:
      "Thank you for installing our alarm system with so little fuss, and so quickly. " +
      "It's peace of mind you cannot put a price on.",
    author: "Monty and Landi",
    displayedName: "Frank and Linda, North West London",
  },
  {
    text:
      "We are so pleased with the service we have received from everyone " +
      "at City Alarms. Everyone has been extremely friendly, professional and knowledgeable.",
    author: "Julia and Dave Secular",
    displayedName: "John and Diane, Hornchurch",
  },
  {
    text:
      "We want to let you know how happy we were with the efficient way your two engineers " +
      "completed the installation. They were both extremely helpful and polite.",
    author: "Jan and Ken Conroy",
    displayedName: "Karen and Peter, Hornchurch",
  },
  {
    text:
      "Just a short note to say how pleased my husband and I were with your engineer and his " +
      "apprentice. They fitted our alarm so cleanly and professionally.",
    author: "Irene and Albert Wright",
    displayedName: "Irene, Harold Wood",
  },
  {
    text: "Thank you for all the help you've given me. I'm very happy with my new alarm system.",
    author: "Anne Marie Tremain",
    displayedName: "Emily, Hornchurch",
  },
  {
    text:
      "City Alarms has provided me the best security I could have hoped for. Thanks for all " +
      'your help, your "after sales" service is first class.',
    author: "Patricia Flowers",
    displayedName: "Pat, North Ockendon",
  },
  {
    text:
      "Great service from survey to installation. " +
      "Nothing was too much trouble for the engineers.",
    author: "Mr and Mrs Farell",
    displayedName: "Ben and Olivia, Hornchurch",
  },
  {
    text: "I've been a customer for 20 years. That says it all!",
    author: "Mr Wong",
    displayedName: "Charlie, Hendon",
  },
  {
    text:
      "We are very pleased with the engineers who installed our alarms and ensured " +
      "that we understood how the system worked. We would definitely recommend this company.",
    author: "Mr and Mrs Marshall",
    displayedName: "Nathan and Victoria, Hornchurch",
  },
  {
    text: "Best alarm service ever!",
    author: "Mr and Mrs Springall",
    displayedName: "Thomas and Charlotte, North London",
  },
]

/**
 * Shuffles an array into a random order.
 *
 * @param array - Array to be shuffled
 * @returns The randomly shuffled array
 */
function shuffleArray(array: any[]) {
  if (array.length > 1) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
  }

  return array
}

function Reviews(): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const webP = useContext(WebPContext)

  return (
    <>
      <Headline title="Customer Reviews" height="37px" width="37px" />
      <QuoteCarousel quotes={shuffleArray(quotes)} />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="body1" component="span" sx={{ textAlign: "left", marginX: 1 }}>
          {isMobile ? null : (
            <MediaCard
              src={webP ? thankyou2 : thankyou2_png}
              text="A sample of thank you cards from our customers."
              float="right"
              width="350px"
              height="255px"
            />
          )}
          As a local family business, we measure our success on the amount of work that we get
          through repeat business and recommendations from our satisfied customers. Every customer
          receives a customer satisfaction survey so that we can continue to meet and exceed their
          expectations.
          <br />
          <br />
          Based on our surveys from the past few years, we have a 100% satisfaction rating, and
          exceed expectations in the vast majority of cases.
          <br />
          <br />
          Take a look at what our customers say about us! These are all genuine quotes from our
          customers, taken from surveys and from the many thank you cards and letters our engineers
          and office staff receive (names may be altered for reasons of anonymity).
          <br />
          <br />
        </Typography>
      </Box>

      {isMobile ? (
        <MediaCard
          src={webP ? thankyou : thankyou_png}
          text="A sample of thank you cards from our customers."
          flex={true}
          width="260px"
          height="300px"
        />
      ) : null}
    </>
  )
}

export type quotesType = typeof quotes
export default Reviews
