import { ReactElement, useContext } from "react"
import { Box, Typography, Link, useTheme, useMediaQuery } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { WebPContext } from "../App"

import Headline from "../components/Headline"
import MediaCard from "../components/MediaCard"
import Accreditations from "../components/Accreditations"
import Map from "../components/Map"

import van from "../assets/van_cropped.webp"
import van_png from "../assets/png/van_cropped.jpg"

/**
 * About Us page.
 *
 * @returns The rendered about us page
 *
 */
function About(): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const webP = useContext(WebPContext)
  const linkSupport = webP
  return (
    <>
      <Headline title="About City Alarms" height="34px" width="34px" />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="body1" component="span" sx={{ textAlign: "left", marginX: 1 }}>
          {isMobile ? null : (
            <MediaCard
              src={webP ? van : van_png}
              text="Our fleet of vans are ready to help you!"
              float="right"
              width="350px"
              height="255px"
            />
          )}
          City Alarms Ltd is a local, family run business founded in 1988, successfully caring for
          the needs of London, Essex and the Home Counties. We understand the importance of keeping
          your loved ones and your property protected from intruders and pride ourselves on
          supplying and fitting full security systems to your specifications.
          <br />
          <br />
          Inspected, approved and quality assured by{" "}
          <Link
            href="https://www.nsi.org.uk/company-finder/?scid=9366301d-4d05-ea11-80fd-00155d993d0a&type=quick&familyType=Security+Systems&town-postcode=&Lo=&La=&range=3&search=company&company-name=City+Alarms&regionId="
            target="_blank"
            rel="noopener noreferrer"
          >
            NSI (the National Security Inspectorate)
          </Link>{" "}
          and accredited to the{" "}
          <Link
            component={Link}
            href={"https://trustedtraders.which.co.uk/businesses/city-alarms-ltd/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Which?
          </Link>{" "}
          trusted Trader Scheme, there is no security company more highly regulated when installing
          and maintaining{" "}
          <Link
            component={linkSupport ? RouterLink : Link}
            to={linkSupport ? "/services/intruder" : undefined}
            href={linkSupport ? undefined : "https://cityalarms.com/services/intruder"}
          >
            Intruder Alarms
          </Link>
          . We also offer a range of additional services such as{" "}
          <Link
            component={linkSupport ? RouterLink : Link}
            to={linkSupport ? "/services/fire" : undefined}
            href={linkSupport ? undefined : "https://cityalarms.com/services/fire"}
          >
            Fire Alarms
          </Link>
          ,{" "}
          <Link
            component={linkSupport ? RouterLink : Link}
            to={linkSupport ? "/services/cctv" : undefined}
            href={linkSupport ? undefined : "https://cityalarms.com/services/cctv"}
          >
            CCTV
          </Link>
          , and{" "}
          <Link
            component={linkSupport ? RouterLink : Link}
            to={linkSupport ? "/services/access" : undefined}
            href={linkSupport ? undefined : "https://cityalarms.com/services/access"}
          >
            Access Control
          </Link>{" "}
          systems for both domestic and commercial properties.
          <br />
          <br />
          {isMobile ? (
            <>
              <MediaCard
                src={webP ? van : van_png}
                text="Our fleet of vans are ready to help you!"
                flex={true}
              />
              <br />
            </>
          ) : null}
          All new systems are installed to current British and European regulations and old systems
          are upgraded to meet modern requirements. All installations, upgrades and repairs are
          carried out to the highest level of workmanship and customer satisfaction. Full security
          surveys and quotations are carried out free of charge and with absolutely no obligation to
          purchase. For excellent customer service from a full accredited company, please look no
          further than City Alarms Ltd.
          <br />
          <br />
          Whether you need to upgrade your current security system or just need it maintained, call
          or email us now at <Link href="tel:01708437000">01708 437000</Link>, or{" "}
          <Link href="mailto:info@cityalarms.com">info@cityalarms.com</Link>. From detection systems
          and fire alarms to CCTV surveillance, we have a solution for your residential and business
          applications.
        </Typography>
      </Box>
      <Accreditations />
      <Map />
    </>
  )
}

export default About
