import { useState, useEffect, useContext, ReactElement } from "react"
import { Link as RouterLink, useLocation } from "react-router-dom"
import {
  Link,
  SwipeableDrawer,
  Typography,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  useTheme,
} from "@mui/material"
import { SvgIconComponent } from "@mui/icons-material/"
import MenuIcon from "@mui/icons-material/Menu"

import { sectionsType } from "../pages/Layout"
import { WebPContext } from "../App"

/**
 * Mobile navigation bar containing links to other pages on the site.
 *
 * @param props.sections - An array of section objects
 * @param props.section.title - The title of the section
 * @param props.section.url - The URL of the section
 * @param props.section.icon - An optional icon component for the section
 * @param props.section.subsections - An array of subsection components. Each subsection
 * has the same properties as a main section object.
 * @returns The rendered mobile navigation bar
 *
 */
function MobileNav({ sections }: { sections: sectionsType }): ReactElement {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent)
  const linkSupport = useContext(WebPContext)
  const path = useLocation().pathname.slice(1)
  const flattenedSections = sections
    .map((section) => (section.subsections.length > 0 ? section.subsections : section))
    .flat()

  useEffect(() => {
    const handlePageShow = () => {
      // Close the drawer when the page is shown
      setIsOpen(false)
    }

    // Add the event listener for 'pageshow'
    window.addEventListener("pageshow", handlePageShow)

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("pageshow", handlePageShow)
    }
  }, [])

  /**
   * Checks if a section is the current url.
   * @param url - The url of the section to check
   *
   * @returns True or False
   */
  function sectionIsCurrent(url: string): Boolean {
    return path.startsWith(url) || (path === "" && url === "/")
  }

  /**
   * Renders an icon appropriate to the given section
   * @param section - The section object whose icon is needed
   * @param section.url - URL of the section
   * @param section.icon - The icon component
   *
   * @returns the rendered icon
   */
  function getIcon(section: { url: string; icon: SvgIconComponent }): ReactElement {
    const SpecificIcon = section.icon
    return (
      <SpecificIcon
        sx={{
          color: sectionIsCurrent(section.url)
            ? theme.palette.background.default
            : theme.palette.text.primary,
        }}
      />
    )
  }

  /**
   * Renders a mobile navigational menu button for the given section
   * @param section - The section object
   * @param section.title - The title of the section
   * @param section.url - URL of the section
   * @param section.icon - The icon component
   *
   * @returns the rendered navigation menu button
   */
  function renderMenuButton(section: {
    title: string
    url: string
    icon: SvgIconComponent
  }): ReactElement {
    return (
      <ListItem key={section.url} disablePadding>
        <ListItemButton
          onClick={() => setIsOpen(false)}
          component={linkSupport ? RouterLink : Link}
          to={linkSupport ? section.url : undefined}
          href={linkSupport ? undefined : "https://cityalarms.com/" + section.url}
          sx={{
            color: sectionIsCurrent(section.url) ? theme.palette.background.default : null,
            backgroundColor: sectionIsCurrent(section.url) ? theme.palette.primary.main : null,
            "&:hover": {
              backgroundColor: sectionIsCurrent(section.url) ? theme.palette.primary.main : null,
            },
          }}
        >
          <ListItemIcon>{getIcon(section)}</ListItemIcon>
          <ListItemText
            primary={section.title}
            primaryTypographyProps={{
              sx: { fontWeight: sectionIsCurrent(section.url) ? "bold" : null },
            }}
          />
        </ListItemButton>
      </ListItem>
    )
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          left: "12px",
          top: "35px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={() => setIsOpen(true)}
          color="inherit"
          aria-label="menu"
          sx={{ padding: 0, margin: 0 }}
        >
          <MenuIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
        <Typography component="span" sx={{ fontSize: "0.8rem" }}>
          Menu
        </Typography>
      </Box>
      <SwipeableDrawer
        disableBackdropTransition={true}
        disableDiscovery={iOS}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          style: {
            width: "220px",
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        <List>{flattenedSections.map((section) => renderMenuButton(section))}</List>
      </SwipeableDrawer>
    </>
  )
}

export default MobileNav
