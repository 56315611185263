import { ReactElement } from "react"
import { Box, Link, Typography, Card, CardActions, CardContent, Button, useTheme } from "@mui/material"

import { ReactComponent as EmailIcon } from "../assets/email.svg"
import { ReactComponent as CallIcon } from "../assets/call.svg"

type ContactCardProps = {
  title: string
  text: string
  url: string
  buttonText: string
}
/**
 * Contact card giving phone number, email address or other information
 *
 * @param title - Title saying what piece of contact information this contains e.g. "phone number"
 * @param text - The contact information e.g. a phone number
 * @param url - The url to follow when clicking the button at the bottom of the card
 * @param buttonText - Text for the button at the bottom of the card e.g. "Call Us!"
 * @returns
 */
function ContactCard({ title, text, url, buttonText }: ContactCardProps): ReactElement {
  const theme = useTheme()
  return (
    <Card raised sx={{ textAlign: "center", height: "100%" }}>
      <CardContent sx={{ paddingBottom: "0", paddingX: "0" }}>
        <Box sx={{ flex: 1 }}>
          {title === "email" ? (
            <EmailIcon
              style={{
                height: "55px",
                width: "auto",
                padding: "5px",
                marginBottom: "5px",
                color: theme.palette.text.paper,
              }}
            />
          ) : (
            <CallIcon
              style={{
                height: "55px",
                width: "auto",
                padding: "5px",
                marginBottom: "5px",
                color: theme.palette.text.paper,
              }}
            />
          )}
        </Box>
        <Typography variant="h3" sx={{ color: theme.palette.text.paper, padding: 0 }}>
          {text}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button size="large" component={Link} href={url}>
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  )
}

export default ContactCard
