import { ReactElement, useContext } from "react"
import { Box, Typography, Link, useTheme, useMediaQuery } from "@mui/material"
import { WebPContext } from "../App"

import Headline from "../components/Headline"
import JobCard from "../components/JobCard"
import MediaCard from "../components/MediaCard"

import staff from "../assets/staff.webp"
import staff_png from "../assets/png/staff.jpg"

/**
 * Careers page consisting of open job positions.
 *
 * @returns The rendered Careers page
 *
 */
function Careers(): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const webP = useContext(WebPContext)
  return (
    <>
      <Headline title="Careers at City Alarms" height="38px" width="38px" />

      <JobCard
        title="Apprentice Engineer"
        responsibilities={
          "Installing and servicing intruder alarms, fire alarms and CCTV, " +
          "responding to customer call outs."
        }
        experience={
          "No previous experience is required. If you are a promising candidate and pass our " +
          "background checks, we will pair you with a senior engineer while you learn on the job."
        }
      />

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="body1" component="span" sx={{ marginX: 1, textAlign: "left" }}>
          {isMobile ? null : (
            <MediaCard
              src={webP ? staff : staff_png}
              text="Come and join our dedicated team!"
              float="right"
              width="350px"
              height="255px"
            />
          )}
          We are always looking for the right staff. Please consider the open positions and forward
          your CV to <Link href="mailto:info@cityalarms.com">info@cityalarms.com</Link>. Even if we
          are not looking for staff at present we will keep your details on file for future
          reference. Our office is located at{" "}
          <Link
            href="https://goo.gl/maps/mD5Dkw9RA5VNgpKw8"
            target="_blank"
            rel="noopener noreferrer"
          >
            142-144 Hillview Avenue, Hornchurch, Essex, RM11&nbsp;2DL
          </Link>
          .
          <br />
          <br />
          Open positions can range from administrative work to engineering roles, and cover varying
          levels of experience from apprentice to fully qualified senior positions. Please note that
          we have very high standards for our employees, and all applicants must pass police
          security clearance and undergo strict background checks.
          <br />
          <br />
        </Typography>
      </Box>
      {isMobile ? (
        <MediaCard
          src={webP ? staff : staff_png}
          text="Come and join our dedicated team!"
          flex={true}
        />
      ) : null}
    </>
  )
}

export default Careers
