import { ReactElement } from "react"
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"

import { ReactComponent as ShieldIcon } from "../assets/shield.svg"
import { ReactComponent as ScrollIcon } from "../assets/scroll.svg"
import { ReactComponent as InfoIcon } from "../assets/info.svg"
import { ReactComponent as IntruderIcon } from "../assets/intruder_orange.svg"
import { ReactComponent as FireIcon } from "../assets/fire_orange.svg"
import { ReactComponent as CCTVIcon } from "../assets/cctv_orange.svg"
import { ReactComponent as AccessIcon } from "../assets/access.svg"
import { ReactComponent as EngineerIcon } from "../assets/engineer.svg"
import { ReactComponent as ReviewIcon } from "../assets/review.svg"
import { ReactComponent as GalleryIcon } from "../assets/gallery.svg"
import { ReactComponent as DownloadIcon } from "../assets/download.svg"
import { ReactComponent as ContactIcon } from "../assets/contact.svg"

type HeadlineProps = {
  title: string
  height?: string
  width?: string
  paddingBottom?: number | undefined
}
/**
 * Headline component which renders the main title of a page.
 *
 * @param title - The text of the headline
 * @param height - Height of the icon
 * @param width - Width of the icon
 * @param paddingBottom - Override paddingTop of container Box
 *
 * @returns The rendered headline component
 *
 */
function Headline({
  title,
  paddingBottom,
  width = "2.3rem",
  height = "2.3rem",
}: HeadlineProps): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  function getIcon() {
    const iconStyle = { height: height, width: "auto", color: theme.palette.secondary.main }
    if (title === "For All Your Security Needs") {
      return <ShieldIcon style={iconStyle} />
    } else if (title.includes("10 Reasons")) {
      return <ScrollIcon style={iconStyle} />
    } else if (title === "About City Alarms") {
      return <InfoIcon style={iconStyle} />
    } else if (title === "Intruder Alarms") {
      return <IntruderIcon style={iconStyle} />
    } else if (title === "Fire Alarms") {
      return <FireIcon style={iconStyle} />
    } else if (title === "CCTV") {
      return <CCTVIcon style={iconStyle} />
    } else if (title === "Access Control and Intercoms") {
      return <AccessIcon style={iconStyle} />
    } else if (title === "Careers at City Alarms") {
      return <EngineerIcon style={iconStyle} />
    } else if (title === "Customer Reviews") {
      return <ReviewIcon style={iconStyle} />
    } else if (title === "Gallery") {
      return <GalleryIcon style={iconStyle} />
    } else if (title === "Downloads") {
      return <DownloadIcon style={iconStyle} />
    } else if (title === "Contact") {
      return <ContactIcon style={iconStyle} />
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingY: isMobile ? 2 : 4,
        paddingBottom: paddingBottom,
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: width,
            height: height,
            marginRight: "1rem",
          }}
        >
          {getIcon()}
        </Box>
      )}
      <Typography variant="h1" sx={{ textAlign: "center" }}>
        {title}
      </Typography>
    </Box>
  )
}

export default Headline
