import { ReactElement, useContext } from "react"
import { Grid, Box, Typography, Link, useTheme, useMediaQuery } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

import ThemeSwitcher from "../components/ThemeSwitcher"
import { WebPContext } from "../App"

type ThemeContextType = "light" | "dark"
type FooterPropsType = {
  setTheme: React.Dispatch<React.SetStateAction<ThemeContextType>>
}
/**
 * Footer component which renders company information, attribution for icons from flaticon.com,
 * and my watermark.
 *
 * @returns The rendered footer component
 *
 */
function Footer({ setTheme }: FooterPropsType): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const webP = useContext(WebPContext)
  const year = new Date().getFullYear()
  return (
    <Box sx={{ borderTop: 1, borderColor: "divider", padding: 1, marginTop: 2 }}>
      <Grid container spacing={1}>
        <Grid item sm={8} xs={12} key="left footer">
          <Typography variant="body2" sx={{ color: theme.palette.text.footer }}>
            Copyright City Alarms Ltd {year}. Company Number 4066642. VAT No. 653&nbsp;2190&nbsp;52
            <br />
            Registered Address:{" "}
            <Link
              href="https://goo.gl/maps/mD5Dkw9RA5VNgpKw8"
              target="_blank"
              rel="noopener noreferrer"
            >
              142-144 Hillview Avenue, Hornchurch, Essex, RM11&nbsp;2DL
            </Link>
            <br />
            <Link href="tel:01708437000">01708&nbsp;437000</Link>,&nbsp;&nbsp;
            <Link href="mailto:info@cityalarms.com">info@cityalarms.com</Link>
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12} key={"right footer"}>
          <Typography
            variant="body2"
            align={isMobile ? "left" : "right"}
            sx={{ color: theme.palette.text.footer }}
          >
            Website by{" "}
            <Link href="https://physicspowered.com" target="_blank" rel="noopener noreferrer">
              TG McDermott, Physics Powered Ltd
            </Link>
            <br />
            Icons courtesy of{" "}
            <Link href="https://www.flaticon.com/" target="_blank" rel="noopener noreferrer">
              Freepik, IconBaandar, Unicon labs
            </Link>
            <br />
            Photo courtesy of{" "}
            {theme.name === "light" ? (
              <>
                <Link
                  href="https://unsplash.com/@alevisionco"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Alev Takil
                </Link>
                {/* <Link href="https://unsplash.com/@aronvandepol">Aron Van de Pol</Link> */}
              </>
            ) : (
              <>
                <Link
                  href="https://unsplash.com/@thkelley"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Thomas Kelley
                </Link>
                {/* <Link href="https://unsplash.com/@dfuchidzhiev">Deniz Fuchidzhiev</Link>,{" "}
                <Link href="https://unsplash.com/@miglu_in_blue">Migle Siauciulyte</Link>,{" "} */}
              </>
            )}
            {webP ? null : (
              <>
                <br />
                <RouterLink to="https://cityalarms.com/contact">No webP</RouterLink>
                <a href="https://cityalarms.com/contact">support detected</a>
              </>
            )}
          </Typography>
          <ThemeSwitcher setTheme={setTheme} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer
