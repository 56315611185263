import { ReactElement, useContext } from "react"
import { Typography, Box, Grid, useTheme, useMediaQuery } from "@mui/material"
import { WebPContext } from "../../App"

import Headline from "../../components/Headline"
import ModalCard from "../../components/ModalCard"

// webp images

import texecom from "../../assets/texecom_premier_LCDP.webp"
import diamondWhite from "../../assets/white.webp"
import polishedChrome from "../../assets/chrome.webp"
import brushedChrome from "../../assets/brushed_chrome.webp"
import black from "../../assets/black.webp"
import grey from "../../assets/gunmetal.webp"
import brass from "../../assets/brass.webp"
import gtag from "../../assets/gardtec_gtag.webp"

import meq from "../../assets/meq_blue.webp"
import digisense from "../../assets/digisense.webp"
import iwise from "../../assets/iwise.webp"
import capture from "../../assets/capture.webp"

import padp2 from "../../assets/padp2.webp"
import flushPa from "../../assets/flush_pa.webp"
import steelPa from "../../assets/steel_pa.webp"

import surfaceDoor from "../../assets/surface_mounted.webp"
import flushDoor from "../../assets/flush_mounted.webp"
import texecomMicro from "../../assets/texecom_micro.webp"

import mcp from "../../assets/mcp.webp"
import smoke from "../../assets/smoke.webp"
import sounder from "../../assets/sounder.webp"
import fireControl from "../../assets/fire_control.webp"

import dome from "../../assets/dome_viper.webp"
import bullet from "../../assets/bullet_viper.webp"

import audio from "../../assets/audio.webp"
import video from "../../assets/video.webp"

// png images

import texecom_png from "../../assets/png/texecom_premier_LCDP.png"
import diamondWhite_png from "../../assets/png/white.png"
import polishedChrome_png from "../../assets/png/chrome.png"
import brushedChrome_png from "../../assets/png/brushed_chrome.png"
import black_png from "../../assets/png/black.png"
import grey_png from "../../assets/png/gunmetal.png"
import brass_png from "../../assets/png/brass.png"
import gtag_png from "../../assets/png/gardtec_gtag.png"

import meq_png from "../../assets/png/meq_blue.png"
import digisense_png from "../../assets/png/digisense.png"
import iwise_png from "../../assets/png/iwise.png"
import capture_png from "../../assets/png/capture.png"

import padp2_png from "../../assets/png/padp2.png"
import flushPa_png from "../../assets/png/flush_pa.png"
import steelPa_png from "../../assets/png/steel_pa.png"

import surfaceDoor_png from "../../assets/png/surface_mounted.png"
import flushDoor_png from "../../assets/png/flush_mounted.png"
import texecomMicro_png from "../../assets/png/texecom_micro.png"

import mcp_png from "../../assets/png/mcp.png"
import smoke_png from "../../assets/png/smoke.png"
import sounder_png from "../../assets/png/sounder.png"
import fireControl_png from "../../assets/png/fire_control.png"

import dome_png from "../../assets/png/dome_viper.png"
import bullet_png from "../../assets/png/bullet_viper.png"

import audio_png from "../../assets/png/audio.png"
import video_png from "../../assets/png/video.png"

/**
 * Gallery page.
 *
 * @returns The gallery page
 */
function Gallery(): ReactElement {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("tb"))
  const webP = useContext(WebPContext)
  return (
    <>
      <Headline title="Gallery" height="34px" width="34px" />

      <Typography
        variant="h2"
        sx={{ marginX: 2, marginTop: 1, marginBottom: 3, textAlign: "center" }}
      >
        Keypads
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 5 }}>
        <Grid item tb={4} sm={6} xs={12} key="texecom lcdp">
          <ModalCard
            cardWidth="280px"
            cardHeight="230px"
            zoomWidth="600px"
            zoomHeight="473px"
            src={webP ? texecom : texecom_png}
            caption="Texecom Premier LCDP."
          />
        </Grid>
        <Grid item tb={4} sm={6} xs={12} key="white">
          <ModalCard
            cardWidth="280px"
            cardHeight="230px"
            zoomWidth="600px"
            zoomHeight="473px"
            src={webP ? diamondWhite : diamondWhite_png}
            caption="Texecom Premier Elite White."
          />
        </Grid>
        <Grid item tb={4} sm={6} xs={12} key="black">
          <ModalCard
            cardWidth="280px"
            cardHeight="230px"
            zoomWidth="600px"
            zoomHeight="473px"
            src={webP ? black : black_png}
            caption="Texecom Premier Elite Black."
          />
        </Grid>
        <Grid item tb={4} sm={6} xs={12} key="polished">
          <ModalCard
            cardWidth="280px"
            cardHeight="230px"
            zoomWidth="600px"
            zoomHeight="473px"
            src={webP ? polishedChrome : polishedChrome_png}
            caption="Texecom Premier Elite Polished Chrome."
          />
        </Grid>
        <Grid item tb={4} sm={6} xs={12} key="brushed">
          <ModalCard
            cardWidth="280px"
            cardHeight="230px"
            zoomWidth="600px"
            zoomHeight="473px"
            src={webP ? brushedChrome : brushedChrome_png}
            caption="Texecom Premier Elite Brushed Chrome."
          />
        </Grid>
        <Grid item tb={4} sm={6} xs={12} key="grey">
          <ModalCard
            cardWidth="280px"
            cardHeight="230px"
            zoomWidth="600px"
            zoomHeight="473px"
            src={webP ? grey : grey_png}
            caption="Texecom Premier Elite Grey."
          />
        </Grid>
        {isTablet ? null : <Grid item tb={2}></Grid>}
        <Grid item tb={4} sm={6} xs={12} key="brass">
          <ModalCard
            cardWidth="280px"
            cardHeight="230px"
            zoomWidth="600px"
            zoomHeight="473px"
            src={webP ? brass : brass_png}
            caption="Texecom Premier Elite Brass."
          />
        </Grid>
        <Grid item tb={4} sm={6} xs={12} key="g-tag">
          <ModalCard
            cardWidth="280px"
            cardHeight="230px"
            zoomWidth="600px"
            zoomHeight="473px"
            src={webP ? gtag : gtag_png}
            caption="Risco GardTec G-Tag."
          />
        </Grid>
        {isTablet ? null : <Grid item tb={2}></Grid>}
      </Grid>

      <Typography
        variant="h2"
        sx={{ marginX: 2, marginTop: 1, marginBottom: 3, textAlign: "center" }}
      >
        Motion Detectors
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 5 }}>
        <Grid item tb={3} sm={6} xs={12} key="meq">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? meq : meq_png}
            caption="Pyronix MEQ Blue."
          />
        </Grid>
        <Grid item tb={3} sm={6} xs={12} key="digisense">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? digisense : digisense_png}
            caption="Risco DigiSense."
          />
        </Grid>
        <Grid item tb={3} sm={6} xs={12} key="iwise">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? iwise : iwise_png}
            caption="iWISE DT AM Grade 3."
          />
        </Grid>
        <Grid item tb={3} sm={6} xs={12} key="capture">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? capture : capture_png}
            caption="Texecom Capture Infrared."
          />
        </Grid>
      </Grid>

      <Typography
        variant="h2"
        sx={{ marginX: 2, marginTop: 1, marginBottom: 3, textAlign: "center" }}
      >
        Panic Alarms
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 5 }}>
        <Grid item tb={4} sm={6} xs={12} key="padp2">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? padp2 : padp2_png}
            caption="PADP2 Panic Alarm."
          />
        </Grid>
        <Grid item tb={4} sm={6} xs={12} key="flushpa">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? flushPa : flushPa_png}
            caption="Flush Panic Alarm."
          />
        </Grid>
        <Grid item tb={4} sm={12} xs={12} key="steelpa">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? steelPa : steelPa_png}
            caption="Steel Panic Alarm."
          />
        </Grid>
      </Grid>

      <Typography
        variant="h2"
        sx={{ marginX: 2, marginTop: 1, marginBottom: 3, textAlign: "center" }}
      >
        Door Contacts
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 5 }}>
        {isTablet ? null : <Grid item tb={2}></Grid>}
        <Grid item tb={4} sm={6} xs={12} key="surface">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? surfaceDoor : surfaceDoor_png}
            caption="Surface mounted door/window contact."
          />
        </Grid>
        <Grid item tb={4} sm={6} xs={12} key="texecom micro">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? texecomMicro : texecomMicro_png}
            caption="Texecom micro door contact / shock detector."
          />
        </Grid>
        {isTablet ? null : <Grid item tb={2}></Grid>}
        <Grid item tb={12} sm={12} xs={12} key="flush">
          <ModalCard
            cardWidth={isTablet ? "280px" : "400px"}
            cardHeight={isTablet ? "180px" : "230px"}
            zoomWidth="600px"
            zoomHeight="335px"
            src={webP ? flushDoor : flushDoor_png}
            caption="Flush mounted magnetic door contact."
          />
        </Grid>
      </Grid>

      <Typography
        variant="h2"
        sx={{ marginX: 2, marginTop: 1, marginBottom: 3, textAlign: "center" }}
      >
        Fire Alarms
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 5 }}>
        <Grid item tb={4} sm={6} xs={12} key="sounder">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? sounder : sounder_png}
            caption="Fire alarm sounder."
          />
        </Grid>
        <Grid item tb={4} sm={6} xs={12} key="smoke alarm">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? smoke : smoke_png}
            caption="Smoke detector with sounder."
          />
        </Grid>
        <Grid item tb={4} sm={12} xs={12} key="mcp">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? mcp : mcp_png}
            caption="Manual fire alarm call point."
          />
        </Grid>
        <Grid item xs={12} key="fire control">
          <ModalCard
            cardWidth={isTablet ? "280px" : "400px"}
            cardHeight={isTablet ? "180px" : "250px"}
            zoomWidth="600px"
            zoomHeight="433px"
            src={webP ? fireControl : fireControl_png}
            caption="Fire alarm control panel."
          />
        </Grid>
      </Grid>

      <Typography
        variant="h2"
        sx={{ marginX: 2, marginTop: 1, marginBottom: 3, textAlign: "center" }}
      >
        CCTV
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 5 }}>
        {isTablet ? null : <Grid item tb={2}></Grid>}
        <Grid item tb={4} sm={6} xs={12} key="dome">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? dome : dome_png}
            caption="Fixed lens dome / turret camera."
          />
        </Grid>
        <Grid item tb={4} sm={6} xs={12} key="bullet">
          <ModalCard
            cardWidth="250px"
            cardHeight="250px"
            zoomWidth="488px"
            zoomHeight="473px"
            src={webP ? bullet : bullet_png}
            caption="Fixed lens bullet camera."
          />
        </Grid>
        {isTablet ? null : <Grid item tb={2}></Grid>}
      </Grid>

      <Typography
        variant="h2"
        sx={{ marginX: 2, marginTop: 1, marginBottom: 3, textAlign: "center" }}
      >
        Access Control and Intercoms
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isTablet ? "column" : "row",
          justifyContent: "space-around",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <ModalCard
          cardWidth={isTablet ? "280px" : "400px"}
          cardHeight={isTablet ? "180px" : "230px"}
          zoomWidth="600px"
          zoomHeight="335px"
          src={webP ? video : video_png}
          caption="Video intercom and handset (example)."
        />
        {isTablet ? <br /> : null}
        <ModalCard
          cardWidth="250px"
          cardHeight="250px"
          zoomWidth="488px"
          zoomHeight="473px"
          src={webP ? audio : audio_png}
          caption="Audio intercom and handset (example)."
        />
      </Box>
    </>
  )
}

export default Gallery
