import { ReactElement, useContext } from "react"
import { Box, Link, useTheme, useMediaQuery } from "@mui/material"
import { WebPContext } from "../App"

import nsi from "../assets/nsi.webp" // 495 x 827
import bt from "../assets/bt.webp" // 800 x 269
import isoqar from "../assets/isoqar.webp" // 600 x 820
import which from "../assets/which.webp" // 708 x 562

import nsi_png from "../assets/png/nsi.png" // 495 x 827
import bt_png from "../assets/png/bt.png" // 800 x 269
import isoqar_png from "../assets/png/isoqar.png" // 600 x 820
import which_png from "../assets/png/which.png" // 708 x 562

function Accreditations(): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const webP = useContext(WebPContext)
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: 3,
          marginBottom: 2,
          marginX: 2,
          width: "100%",
          maxWidth: "900px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 120,
            width: 72,
            marginBottom: isMobile ? 1 : 0,
          }}
        >
          <Link
            href={
              "https://www.nsi.org.uk/company-finder/?scid=9366301d-4d05-ea11-80fd-00155d993d0a&type=quick&familyType=Security+Systems&town-postcode=&Lo=&La=&range=3&search=company&company-name=City+Alarms&regionId="
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "flex" }}
          >
            <img src={webP ? nsi : nsi_png} alt="NSI gold approval" style={{ width: "100%" }} />
          </Link>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 110,
            width: 139,
            marginY: isMobile ? 1 : 0,
          }}
        >
          <Link
            href={"https://trustedtraders.which.co.uk/businesses/city-alarms-ltd/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={webP ? which : which_png}
              alt="Which? Recommended trader"
              style={{ width: "100%" }}
            />
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: isMobile ? 65 : 120,
            width: 193,
            marginY: isMobile ? 1 : 0,
          }}
        >
          <img src={webP ? bt : bt_png} alt="BT Redcare" style={{ width: "100%" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 120,
            width: 88,
            marginTop: isMobile ? 1 : 0,
          }}
        >
          <img
            src={webP ? isoqar : isoqar_png}
            alt="ISOQAR Quality Approved"
            style={{ width: "100%" }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Accreditations
